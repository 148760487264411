import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { globalState } from 'shared/foreground/models';
import background from 'shared/foreground/portalGates/toBackground';
import { saveNewDocument } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/create';
import copyTextToClipboard from 'shared/foreground/utils/copyTextToClipboard';

import Button from '../Button';
import { FileUploadContext } from '../FileDropzone';
import StrokeLinkIcon from '../icons/24StrokeLinkIcon';
import StrokeUploadIcon from '../icons/24StrokeUploadIcon';
import EmailsIcon from '../icons/EmailsIcon';
import SolidTwitterIcon from '../icons/SolidTwitterIcon';
import { UpdateImportEmailAddress } from '../UpdateImportEmailAddress';
import { AccountSettingsPage, List, ListItem } from './AccountSettingsPage';
import styles from './AddToLibraryPage.module.css';

export function AddToLibraryPage() {
  return (
    <AccountSettingsPage
      subtitle="Library is where documents you manually save for yourself live"
      title="Add to Library"
    >
      <AddToLibrary />
    </AccountSettingsPage>
  );
}

const AddToLibrary = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const { openFileDialog } = useContext(FileUploadContext);
  const profile = globalState(useCallback((state) => state.client.profile, []));
  const libraryEmail = profile?.custom_library_email;
  const [updateEmailDialogIsOpen, setUpdateEmailDialogIsOpen] = useState(false);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!url) {
      return;
    }

    setIsLoading(true);
    await saveNewDocument({ source: 'Reader add from import URL', url }, { userInteraction: 'unknown' });
    background.pollLatestState(20);
    history.push('/library');
  };

  const buttonValue = isLoading ? 'Loading...' : 'Add';

  const copyLibraryEmail = useCallback(() => {
    if (libraryEmail) {
      copyTextToClipboard(libraryEmail);
    }
  }, [libraryEmail]);

  return (
    <List className={styles.wrapper}>
      <ListItem
        image={<StrokeLinkIcon className={styles.primaryIcon} />}
        title="Add from URL"
        subtitle="Add a document directly from a URL"
        isSmall
      >
        <div className={styles.inputWithButton}>
          <form onSubmit={onSubmit}>
            <input
              required
              aria-labelledby="add from url"
              onChange={(e) => setUrl(e.target.value)}
              type="url"
              autoComplete="off"
              placeholder="http://"
            />
            <Button variant="secondary" type="submit">
              {buttonValue}
            </Button>
          </form>
        </div>
      </ListItem>
      <ListItem
        image={<StrokeUploadIcon className={styles.primaryIcon} />}
        title="Upload file"
        subtitle=".epub, .pdf, .opml, .xml, .csv supported. 500MB max."
        isSmall
      >
        <div className={styles.uploadFile}>
          <Button variant="secondary" onClick={openFileDialog}>
            Browse files
          </Button>
          <p>Or drop a file anywhere on top of Reader</p>
        </div>
      </ListItem>
      <ListItem
        image={<EmailsIcon className={styles.emailIcon} />}
        title="Forward email"
        subtitle="To import any email to your Inbox, forward to:"
        isSmall
      >
        <div className={styles.libraryEmail}>
          <div className={styles.inputWithButton}>
            <input
              onClick={copyLibraryEmail}
              aria-labelledby="forward email"
              type="email"
              readOnly
              value={libraryEmail}
              autoComplete="off"
            />
            <Button variant="secondary" onClick={copyLibraryEmail}>
              Copy
            </Button>
          </div>
          <Button
            className={styles.suggestedFeedsButton}
            onClick={() => setUpdateEmailDialogIsOpen(true)}
          >
            Personalize email addresses
          </Button>
        </div>
      </ListItem>
      {updateEmailDialogIsOpen && (
        <div>
          <UpdateImportEmailAddress setUpdateEmailDialogIsOpen={setUpdateEmailDialogIsOpen} />
        </div>
      )}
      <ListItem
        image={<SolidTwitterIcon className={styles.twitterLogo} />}
        title="Add from Twitter"
        subtitle="You can save both threads and individual tweets"
        isSmall
      >
        <div className={styles.addFromTwitter}>
          <p>Save a Twitter thread</p>
          <ul>
            <li>
              Publicly reply with “<b>@readwise save thread</b>“
            </li>
            <li>
              Silently direct message <b>@readwise</b> the word “<b>save thread</b>”
            </li>
          </ul>
        </div>
      </ListItem>
    </List>
  );
};
